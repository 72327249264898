<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">人员查询</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">部门岗位</div>
					<div class="inputs_box">
						<bumen_cascader ref="bumen_cascader" @confirm="confirm_bumen_cascader_value" />
						<!-- <el-cascader placeholder="请选择" :options="bm_list" collapse-tags :props="props" :disabled="$route.query.id?true:false"
						:show-all-levels="false" v-model="bm_list_ac" clearable></el-cascader> -->
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">月份</div>
					<div class="inputs_box">
						<el-date-picker :clearable="true" v-model="month_select" value-format="yyyy-MM-DD" type="month"
							placeholder="选择日期" :picker-options="pickerOptions">
						</el-date-picker>
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
					<div class="btn_ends" @click="to_bumen_kaoqin_mingxi()">打卡明细</div>
					<div class="btn_ends" @click="to_bumen_daka_mingxi()">考勤明细</div>
					<div class="btn_ends" @click="to_bumen_kaoqin()">部门考勤</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					考勤管理
				</div>
				<div class="rights">
					<div class="btns" @click="do_qrkq()">
						确认当月考勤
					</div>
					<div class="btns" @click='do_export'>
						新增补卡/年休/登山
						<!-- <el-dropdown  @command="do_import"  trigger="click">
							<div class="el-dropdown-link">
								新增补卡/年休/登山
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item,index) in btn_list" :key="index" :command="item">
									<div class="btn_text_dr_sb">{{ item.title }}</div>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown> -->
					</div>
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55"></el-table-column> -->
					<el-table-column fixed prop="name" label="姓名" width="120"></el-table-column>
					<el-table-column prop="" label="基本信息" width="360">
						<el-table-column prop="_bumen" label="部门" width="120"></el-table-column>
						<el-table-column prop="_position" label="岗位" width="120"></el-table-column>
						<!-- <el-table-column prop="id4" label="考勤类别" width="120"></el-table-column> -->
					</el-table-column>
					<el-table-column prop="" label="出勤统计" width="120">
						<el-table-column prop="workDay" label="应出勤天数" width="120"></el-table-column>
						<el-table-column prop="realDay" label="实际出勤的天数" width="120"></el-table-column>
						<el-table-column prop="overHour" label="加班时长（记加班费）(小时)" width="200"></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="异常统计" width="120">
						<el-table-column prop="lateNum" label="迟到次数" width="120"></el-table-column>
						<el-table-column prop="lateMinute" label="迟到时长(分钟)" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="请假统计" width="120">
						<el-table-column prop="yearHoliday" label="年休假" width="120"></el-table-column>
						<el-table-column prop="holiday" label="节日" width="120"></el-table-column>
						<el-table-column prop="sickLeave" label="病假" width="120"></el-table-column>
						<el-table-column prop="absenceLeave" label="事假" width="120"></el-table-column>
						<el-table-column prop="workLeave" label="工伤" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="" label="带班下井补助" width="120">
						<el-table-column prop="daiaban0" label="零点带班" width="120"></el-table-column>
						<el-table-column prop="daiaban4" label="四点带班" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="" label="下井补助" width="120">
						<el-table-column prop="xiajing" label="下井" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="其他补助" width="120">
						<el-table-column prop="climbTime" label="登山次数" width="120"></el-table-column>
						<el-table-column prop="shangquTime" label="山区补助" width="120"></el-table-column>
					</el-table-column>

				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		<up_files ref="up_files" @handle_up_end="handle_up_end"></up_files>


	</div>
</template>

<script>
	import dayjs from 'dayjs';

	//
	import bumen_cascader from '@/components/bumen/bumen_cascader.vue'; //部门选择
	import page from "@/components/page/page.vue"; //分页  统一的

	export default {
		components: {
			bumen_cascader,
			page,
		},
		data() {
			return {
				month_select: dayjs().format('YYYY-MM'), //考勤日期
				bumen_position_info: {}, //
				//所选的部门岗位信息


				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
				},
				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				bm_list: [],//部门
				bm_list_ac: [],//部门选中
				props: {
					multiple: false,
					value: 'id',
					label: 'title'
				},
				pickerOptions: {
					disabledDate(time) {
						console.log('time', time)
						return (time.getMonth()) > (new Date().getMonth());
					}
				},
			};
		},
		computed: {},
		watch: {
			month_select(value) {
				console.log('选择的月份', value)
			}
		},

		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
			this.get_bm_list()
		},
		mounted() {

		},
		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("kaoqin/getMonthList", {
					...this.pages,
					month: this.month_select, //日期
					...this.bumen_position_info, //部门岗位id
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list;
						list.forEach(v => {
							// v._name = user.name;
							let user = v;
							// v._bumen = user.depart_title || '';
							v._bumen = this.mix_format_bumen_title(user.depart_title);
							v._position = user.level6;
						})
						this.list = list
					} else {
						alertErr(res.msg)
					}
				});
			},
			do_qrkq(){
				if (!this.bumen_position_info.deptId) {
					alertErr('请先选择部门')
					return
				}
				if (!this.month_select) {
					alertErr('请先选择月份')
					return
				}
				this.$confirm('是否确定当月考勤?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					this.$api("kaoqin/confirmMonthKaoqin", {
						deptId:this.bumen_position_info.deptId,
						month:this.month_select
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.get_list()
						}
					});
				 
				}).catch(() => {
				          
				});
			},
			 
			do_export() {
				if (!this.bumen_position_info.deptId) {
					alertErr('请先选择部门')
					return
				}
				if (!this.month_select) {
					alertErr('请先选择月份')
					return
				}
				this.get_list()
				let obj={
					file_url:`http://jinniu.dx.hdapp.com.cn/api/kaoqin/exportKqTemp?depart_id=${this.bumen_position_info.deptId}&month=${this.month_select}&token=${localStorage.getItem('token')}`,
				}
				this.$refs.up_files.handle_open(obj)
			},
			handle_up_end(info){
				console.log(info)
				
				// this.$api("socialImportXls", {
				// 	file: info.path,
				// 	type: this.btn_ac_obj.value
				// }, "post").then((res) => {
				// 	alert(res)
				// 	if (res.code == 200) {
				// 		this.get_list()
				// 	}
				// });

			},

			confirm_bumen_cascader_value(value) {
				this.$log('勾选部门数据', value)
				let departIds = '';

				if (typeof value[0] == 'object') {

					let last_id_arr = value.map(arr => arr[arr.length - 1])

					departIds = last_id_arr.join(',')

				} else {

					departIds = value[value.length - 1];

				}

				let departItems = JSON.stringify(value)

				this.$log('departIds', departIds)
				this.$log('departItems', departItems)

				this.bumen_position_info = {
					deptId: departIds
				}
			},


			// 打卡明细
			to_bumen_kaoqin_mingxi() {
				if (!this.bumen_position_info.deptId) {
					alertErr('请先选择部门')
					return
				}
				if (!this.month_select) {
					alertErr('请先选择月份')
					return
				}
				this.$router.push('Open_Details?id=' + this.bumen_position_info.deptId + '&month=' + this
					.month_select)

			},
			//考勤明细
			to_bumen_daka_mingxi() {
				if (!this.bumen_position_info.deptId) {
					alertErr('请先选择部门')
					return
				}
				if (!this.month_select) {
					alertErr('请先选择月份')
					return
				}
				this.$router.push('Record_sheet?id=' + this.bumen_position_info.deptId + '&month=' + this
					.month_select)
			},
			//部门考勤
			to_bumen_kaoqin() {
				if (!this.bumen_position_info.deptId) {
					alertErr('请先选择部门')
					return
				}
				if (!this.month_select) {
					alertErr('请先选择月份')
					return
				}
				this.$router.push('Departmental_Attendance?id=' + this.bumen_position_info.deptId + '&month=' + this
					.month_select)
			},



			//获取部门
		get_bm_list() {
			// this.$nextTick(() => {
			// 	this.$refs.bumen_cascader.init({
			// 		type: '设置数据',
			// 		value: [3, 11, 12],
			// 	})
			// })
			return

			this.loading = true
			this.$api("getCategoryAllList", {

			}, "get").then((res) => {
				console.log('所有的部门', res)
				this.loading = false
				if (res.code == 200) {
					let level = 0//最高等级
					let list = []
					let data = []
					res.data.forEach((e) => {
						if (e.is_gw != 1) {
							data.push(e)
						}
					})
					data.forEach((e, i) => {
						if (e.level > level) {
							level = e.level
						}
						if (e.is_gw != 1) {
							this.$set(e, 'children', [])
						}
					})
					console.log('最高登记', level)


					for (let i = 1; i <= level; i++) {
						let level_list = []
						data.forEach((e) => {
							if (e.level == i) {
								level_list.push(e)
							}


						})
						list.push(level_list)
					}
					console.log('列表1', list)
					// 总列表  最后一位的下标
					this.bm_list_data(list, list.length - 1)
				} else {
					alertErr(res.msg)
				}
			});
			},
			//处理部门列表
			bm_list_data(lists, indexs) {
				let index1 = indexs //当前下标
				let index2 = indexs - 1 //上级下标
				let list = lists
				list[index1].forEach((e) => {
					list[index2].forEach((v) => {
						if (v.id == e.parent_id) {
							v.children.push(e)
						}
					})
				})
				list[index2].forEach((e) => {
					if (e.children.length == 0) {
						this.$delete(e, 'children')
					}
				})
				if (index2 == 0) {
					console.log('最后的数据', list)
					this.bm_list = list[0]
					return
				}
				this.bm_list_data(list, index2)
			},

		},



	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;
						margin-right: 28px;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;
						margin-left:30px;
						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>